

import React, {MouseEventHandler} from "react";
import type { MenuProps } from 'antd';
import {Layout, Menu} from 'antd';
import '../styles/menu.scss'
import { useAppSelector } from "../redux/hooks";

const {  Content,  Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}


type Props = {

    onCategoryChangeHandler: (e: any) => void

}

const Sidebar  = ({onCategoryChangeHandler}: Props) => {
 const categories = useAppSelector(state => state.products.categories)

    const items2 = categories?.map(
        (it) => {
            return {
                key: `${it.id}`,
                label: ` ${it.name}`,
                value: `${it.id}`,

                children: it?.child_category.map((el) => {
                    return {
                        key: el.id,
                        label: `${el.name}`,
                        value: `${el.id}`,
                    };
                }),
            };
        });


  return <div>
      <Layout>
          <Content style={{width: '100%'}}>
              <Layout >
                  <Sider  width={250}
                          onClick = {(e:any) => onCategoryChangeHandler(e)}
                          className={'sider'}
                  >
                      <Menu
                          mode="inline"
                          defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          style={{ height: '100%' }}
                          items={items2}
                          onClick={e => onCategoryChangeHandler(e)}
                      />
                  </Sider>
              </Layout>
          </Content>
      </Layout>
  </div>;
};

export default Sidebar;
