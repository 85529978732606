
import React, { FC } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../styles/mainSlider.scss'
import { ISliderData } from "../types";
import { useAppSelector } from "../redux/hooks";
import { Link } from "react-router-dom";



const MainSlider: FC = () => {
	const slider: ISliderData[] = useAppSelector(state => state.products.sliderData)


  return <div className="main_slider">
	 <Carousel
	 	showStatus={false}
		showArrows={true}
		infiniteLoop={true}
		autoPlay={true}
		stopOnHover={true}
		showThumbs={false}
	 >
		{
			slider?.map((it, index) => (
				<div className="main_slide_wrapper" key={index} >
					<div className="main_img_wrapper">
						<img src={it?.photo} alt={''} className="main_slide_img"/>
					</div>
                    
                    <div className="main_slide_text">
						<div>
							<div className="main_slider_subtitle">{it?.description}</div>
							<div className="main_slider_title">{it?.title}</div>
							<div className="main_btn_wrapper">
								<Link to='/catalogue' style={{textDecoration: 'none'}} className="main_slider_btn">В каталог</Link>
							</div>
							
						</div>
					</div>
                </div>
			))
		}
            </Carousel>
  </div>
};

export default MainSlider;
