import axios from 'axios'

const BASE_URL = 'https://api.bikini.kg/api/v1'

export const publicRequest = axios.create({
	baseURL: BASE_URL
})




