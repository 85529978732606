import { createSlice} from "@reduxjs/toolkit";
import { IInitialCartState } from "../types";

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        total_quantity: 0,
        total_sum: 0,

    } as IInitialCartState,
    reducers: {
        addToCart: (state, action) => {
            state.cart = [...state.cart, action.payload];
            state.total_quantity = state.cart.reduce(((acc, it) => acc += it.quantity), 0) ;
            state.total_sum = state.cart.reduce((acc, it) => acc += (it.quantity * Number(it.price)), 0) ;
        },
        removeFromCart: (state, action) => {
            const cart = state.cart.filter(el => el.id !== action.payload)
            if(cart){
               state.cart = cart;
               state.total_quantity = cart.reduce((acc, it) => (acc += it.quantity), 0) ;
               state.total_sum = cart.reduce((acc, it) => (acc += (it.quantity * Number(it.price))), 0) ;
            }
        },
        updateCartQuantity: (state, action) => {
            const index = state.cart.findIndex(it => it.id === action.payload.id)
            state.cart[index].quantity += action.payload.quantity
            state.total_quantity += action.payload.quantity

            if(action.payload.quantity > 0){
                state.total_sum += Number(state.cart[index].price)
            } else{
                state.total_sum -= Number(state.cart[index].price)
            }
        },

        resetCart: (state) => {
            state.cart = [];
            state.total_quantity = 0;
            state.total_sum = 0
        }
    }
})


export const {addToCart, removeFromCart, resetCart, updateCartQuantity} = cartSlice.actions
export default cartSlice.reducer