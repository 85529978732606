
import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import '../styles/cart.scss'
import {Modal, Form, Input} from "antd";
import {createOrder} from "../redux/ProductSlice";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { removeFromCart, resetCart, updateCartQuantity } from '../redux/CartSlice';
import Menu from '../components/Menu';
import CatalogueTopBar from '../components/CatalogueTopBar';
import Footer from '../components/Footer';
import ScrollToTop from "../components/ScrollToTop";

const Cart = () => {
    const cart = useAppSelector(state => state.cart.cart)
    const total_quantity = useAppSelector(state => state.cart.total_quantity)
    const total_sum = useAppSelector(state => state.cart.total_sum)
    const dispatch = useAppDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [secondModalOpen, setSecondModalOpen] = useState(false);
    const [name, setName] = useState<string | undefined>(undefined)
    const [phone, setPhone] = useState<string | undefined>(undefined)
    const [address, setAddress] = useState<string>()
    const [form] = Form.useForm();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const contactsRef = useRef() as MutableRefObject<HTMLDivElement>


    function getWindowDimensions() {
        if (typeof window !== 'undefined') {
            const {innerWidth: width, innerHeight: height} = window;
            return {
                width,
                height
            };
        }
    }


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const changeProductQuantity =  (id: number, product_number: number) => {
        // @ts-ignore
        if((cart?.find(it => it?.id === id)?.quantity + product_number) > 0){
                dispatch(updateCartQuantity({id, quantity: product_number}))
        }
    }

    const scrollToComponent = (component: string) => {
        if(component === 'contacts'){
            contactsRef.current.scrollIntoView()
        }
    }

    const sendForm = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        try{
            await form.validateFields()

        if(name  && phone && cart.length > 0){
            const order = cart.map(it => ({
                product: it.productId,
                price: Number(it.price),
                quantity: it.quantity,
                color: it.color,
                size: it.size
            }))

            const res = await dispatch(createOrder({
                name,
                phone_number: phone,
                address,
                products: order
            }))

            if(res) {
                form.resetFields()
                setName('')
                setPhone('')
                setAddress('')
                setIsModalOpen(false)
                setSecondModalOpen(true)
                dispatch(resetCart())
            }}
            }catch(error){
                console.log(error)
            }
    }

    return <div>
        <div className="menu_wrapper">
            <Menu scrollToComponent={scrollToComponent}/>
        </div>
        <div className={'cart_wrapper'}>
            <CatalogueTopBar name={"КОРЗИНКА"}/>
            <div className="cart">
                {windowDimensions && windowDimensions.width > 850
                    ? <table className={'table'}>
                        <tbody>
                        <tr className={'tr'}>
                            <th className={'th'}>№</th>
                            <th className={'th'}>Фото</th>
                            <th className={'th'}>Наименование</th>
                            <th className={'th'}>Цена</th>
                            <th className={'th'}>Количество</th>
                            <th className={'th'}>Итого</th>
                            <th className={'th'}></th>
                        </tr>
                        {
                            cart?.map((it, index) => (
                                <tr className={'tr'} key={index}>
                                    <td className={'td'}>{index + 1}</td>
                                    <td className={'td'}>
                                        <div className="td_image">
                                            <img src={ it?.img} alt={''} className='cart_img'/>
                                        </div>
                                    </td>
                                    <td  className={'td'}>
                                        <div className="td_description">
                                            <div>{it?.name}</div>
                                            <div><div className={'gray'}>размер:</div> &nbsp; <div>{it?.size}</div></div>
                                            <div><div className={'gray'}>цвет:</div> &nbsp; <div className={'color'} style={{background: it?.color}}></div></div>
                                        </div>
                                    </td>
                                    <td className={'td'}>{it?.price} сом</td>
                                    <td className={'td'}>
                                        <div className="td_quantity">
                                            <button className="quantity_btn" onClick={() => changeProductQuantity(it.id, -1)}>-</button>
                                            <div>{it?.quantity}</div>
                                            <button className="quantity_btn" onClick={() => changeProductQuantity(it.id, 1)}>+</button>
                                        </div>
                                    </td>
                                    <td className={'td'}>
                                        {(it.quantity * it.price).toFixed(2)} &nbsp;
                                        cом</td>
                                    <td className={'td'}>
                                        <button onClick={() => dispatch(removeFromCart(it?.id))}>x</button>
                                    </td>
                                </tr>

                            ))
                        }
                        <tr>
                            <td className={'td'}></td>
                            <td className={'td'} style={{ fontWeight: '600'}}>Итого:</td>
                            <td className={'td'}></td>
                            <td className={'td'}></td>
                            <td className={'td'} style={{paddingLeft: '65px', fontWeight: '600'}}>
                                {total_quantity}шт
                            </td>
                            <td className={'td'} style={{ fontWeight: '600'}}>
                                {total_sum.toFixed(2)} сом
                            </td>
                            <td className={'td'}></td>
                        </tr>
                        </tbody>
                    </table>
                    : <table className={'mobile_table'}>
                        <tbody>
                        {
                            cart.map((it, index) => (
                                <tr className={'mobile_tr'}>
                                    <td className={'mobile_td'}>
                                        <div style={{margin: '5px'}}>
                                            {index+ 1}.
                                        </div>
                                        <div className="mobile_img_block">
                                            <img src={ it?.img} className='cart_mobile_img'
                                                 width={windowDimensions && windowDimensions.width  > 475 ? 180 : 100} height={windowDimensions && windowDimensions.width > 475 ? 180 : 100} alt={''}/>
                                        </div>
                                        <div className="mobile_text_block">
                                            <div className="mobile_title">{it.name}</div>
                                            <div className="mobile_price">{it.price} сом</div>
                                            <div className="mobile_size"><div style={{color: 'grey'}}>Pазмер:</div> &nbsp; {it.size}</div>
                                            <div className={'mobile_color_block'}>Цвет: &nbsp;
                                                <div className="mobile_color" style={{backgroundColor: it?.color}}></div>
                                            </div>
                                            <div className="td_quantity" style={{
                                                margin: '5px -15px'
                                            }}>
                                                <button className="quantity_btn" onClick={() => changeProductQuantity(it.id, 1)}>+</button>
                                                <div>{it?.quantity} шт</div>
                                                <button className="quantity_btn" onClick={() => changeProductQuantity(it.id, -1)}>-</button>
                                            </div>
                                        </div>
                                        <td>
                                            <button onClick={() => dispatch(removeFromCart(it?.id))} style={{border: '1px solid silver', background: 'none',  color: 'gray'}}>x</button>
                                        </td>
                                    </td>
                                </tr>))
                        }
                        <tr className={'mobile_total'}>
                            <td style={{fontWeight: '600'}}>
                                <div>Итого:</div>
                                <div>
                                    {total_quantity ? total_quantity : null} шт &nbsp; &nbsp;
                                    {total_sum ? total_sum.toFixed(2) : null } сом
                                </div>
                            </td>
                            <td></td>
                        </tr>

                        </tbody>
                    </table>
                }
                <div className={'btn_wrapper'}>
                    <button  className='mobile_clear_btn' onClick={() => dispatch(resetCart())}>Очистить корзину</button>
                    <button  className='mobile_clear_btn' onClick={() => setIsModalOpen(true)}>Оформить заказ</button>
                </div>

            </div>
            <div ref={contactsRef}>
                <Footer/>
            </div>
        </div>


        <Modal
            // width={600}
            onCancel={() =>  setIsModalOpen(false)}
            style={{ borderRadius: '10px'}}
            cancelButtonProps={{style: { display: 'none', color: 'whitesmoke' }}}
            okButtonProps={{ style: { display: 'none' } }}
            className='modal'
            open={isModalOpen}>
            <Form name={'modalForm'} className="modal" form={form}>
                <div className="modal_logo">
                    BIKINI.KG
                </div>
                {/*<div className="modal_title">Ваш заказ:</div>*/}
                <div className='modal_data'>
                    Ваш заказ:  <strong>{total_quantity} товара(oв)</strong> на <strong>{total_sum.toFixed(2)} сом </strong>
                </div>
                    <div className="modal_form">
                        <Form.Item
                            name='username'
                            className="form_item"
                            rules={[{required: true, message: 'Укажите ваше имя'}]}
                            label="Имя"
                        >
                            <Input
                                onChange={(e) => setName(e.target.value)}
                                className='input_modal'
                                value={name}
                            />
                        </Form.Item>
                        <Form.Item
                            name='phone'
                            className="form_item"
                            rules={[{required: true, message: 'Укажите ваш номер телефона'}]}
                            label="Телефон"
                        >
                            <Input
                                onChange={(e) => setPhone(e.target.value)}
                                className='input_modal'
                                value={phone}
                            />
                        </Form.Item>
                        <Form.Item
                            name='address'
                            className="form_item"
                            label="Адрес"
                        >
                            <Input
                                onChange={(e) => setAddress(e.target.value)}
                                className='input_modal'
                            />
                        </Form.Item>
                    </div>
                    <div className='modal_btn_wrapper'>
                        <button  type='submit' onClick={(e) => sendForm(e)} className='clear_btn'>Заказать</button>
                    </div>
            </Form>
        </Modal>
        <Modal
            width={350}
            open={secondModalOpen}
            style={{  borderRadius: '10px', color:'whitesmoke'}}
            onCancel={() =>  setSecondModalOpen(false)}
            cancelButtonProps={{style: { display: 'none' }}}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <div className='secondModal'>
                <div style={{ width: '100%', display: 'flex'}}>
                </div>
                <div style={{color: '#2a2929', textAlign: 'center', fontSize: '19px', letterSpacing: '1px', marginBottom: '10px', fontWeight: '600'}}>Вы заказали товар! </div>
                <div style={{ textAlign: 'center', color: '#716f6f',  letterSpacing: '1px', fontSize: '12px'}}>Наш специалист свяжется с вами. Благодарим за обращение!</div>
                <div style={{display: 'flex', justifyContent: 'center', margin: '20px auto'}}>
                <button className='clear_btn' onClick={() =>  setSecondModalOpen(false)}>Закрыть</button>
                </div>
            </div>
        </Modal>
        <ScrollToTop />
    </div>
};

export default Cart;