import React from "react";
import './../styles/menu.scss'
import {ShoppingCartOutlined} from "@ant-design/icons";
import { useAppSelector } from "../redux/hooks";
import { Link } from "react-router-dom";

type MenuProps = {
    scrollToComponent: (component: string) => void 
}

const Menu: React.FC<MenuProps> = ({scrollToComponent}) => {
    const cart = useAppSelector(state => state.cart.cart)

  return <div className='menu'>
	<Link to ='/' className="logo" style={{textDecoration: 'none', color: 'inherit'}}>
		BIKINI.KG
	</Link>
	<div className="menu_content">
		<Link to='/catalogue' style={{textDecoration: 'none', color: 'inherit'}}>
			<div className="menu_item">
                Каталог
            </div>
		</Link>
		<div className="menu_item" onClick={()=> scrollToComponent('contacts')}>
			Контакты
		</div>
	</div>

            <div className="menu_icons">
                <Link to='/cart' style={{color: "inherit"}}>
            <ShoppingCartOutlined style={{fontSize: '20px', fontWeight: '100'}}/>
            {Array.isArray(cart) && cart.length> 0 ? <div className="ordered">
                { cart.length}
            </div> : null}
                </Link>
            </div>
  </div>;
};


export default Menu;