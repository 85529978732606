
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IInitialState, IOrder } from '../types';
import { publicRequest } from '../utils';

const initialState = {
	products: [],
	product: undefined,
	sliderData: [],
	loading: false,
	categories: [],
	contacts: [],
	aboutUs: {},
    order: [],
    promotions: [],
    similarProducts: []
} as IInitialState;


export const getProducts = createAsyncThunk(
	'products/getProducts',
	async () => {
		try {
			const {data} = await publicRequest.get('/product/')
		
            return data
		} catch (error) {
			 console.log(error)
		}
	}
)

export const getProductDetails = createAsyncThunk(
	'products/getProductDetails',
	async (id: number)=> {
		try {
            if(id) {
                const {data} = await publicRequest.get(`/product/${id}/`)

                return data
            }

		} catch (error) {
			 console.log(error)
		}
	}
)

export const getSimilarProducts = createAsyncThunk(
    'products/getSimilarProducts',
    async (id: Number)=> {
        try {
            const {data} = await publicRequest.get(`/similar/products/${id}/`)

            return data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getPromotions = createAsyncThunk(
    'products/getPromotions',
    async ()=> {
        try {
            const {data} = await publicRequest.get(`/promotions/`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getProductsByCategory = createAsyncThunk(
    'products/getProductsByCategory',
    async (id: Number)=> {
        try {
            const {data} = await publicRequest.get(`/product/by_category/${Number(id)}/`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getSliderData = createAsyncThunk(
	'main/getSlider',
	async () => {
		try {
			const {data} = await publicRequest.get('/main-slider/')
			return data 
		} catch (error) {
			console.log(error)
		}
	}
)


export const getCategories = createAsyncThunk(
	'main/getCategories',
	async() => {
		try {
			const {data} = await publicRequest.get('/category/')
			return data
		} catch (error) {
			console.log(error)
		}
	}
)



export const createOrder = createAsyncThunk(
	'products/create-order',
	async(params: IOrder) => {
		try {
			const {data} = await publicRequest.post(`/order/`, params)
            console.log('data', data)
			return data
		} catch (error) {
			console.log(error)
		}
	}
)


export const getContacts = createAsyncThunk(
	'cars/contacts',
	async() => {
		try {
			const {data} = await publicRequest.get(`/contacts/`)
			return data
		} catch (error) {
			console.log(error)
		}
	}
)


export const productSlice = (createSlice({
	name: 'products',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(getProducts.pending, state => {
				state.loading =  true
			}) 
			.addCase(getProducts.fulfilled, (state, action) => {
				state.products = action.payload
				state.loading =  false
			}) 
			.addCase(getProducts.rejected, state => {
				state.products = []
			}) 

			.addCase(getProductDetails.pending, state => {
				state.loading =  true
			}) 
			.addCase(getProductDetails.fulfilled, (state, action) => {
				state.product = action.payload
				state.loading =  false
			}) 
			.addCase(getProductDetails.rejected, state => {
				state.product = undefined
			})

            .addCase(getProductsByCategory.pending, state => {
                state.loading =  true
            })
            .addCase(getProductsByCategory.fulfilled, (state, action) => {
                state.products = action.payload
                state.loading =  false
            })
            .addCase(getProductsByCategory.rejected, state => {
                state.products = []
            })


            .addCase(getSliderData.pending, state => {
				state.loading =  true
			}) 
			.addCase(getSliderData.fulfilled, (state, action) => {
				state.sliderData = action.payload
				state.loading =  false
			}) 
			.addCase(getSliderData.rejected, state => {
				state.loading =  false
			}) 


			.addCase(getCategories.pending, state => {
				state.loading =  true
			}) 
			.addCase(getCategories.fulfilled, (state, action) => {
				state.categories = action.payload
				state.loading =  false
			}) 
			.addCase(getCategories.rejected, state => {
				state.loading =  false
			}) 


			.addCase(getContacts.pending, state => {
				state.loading =  true
			}) 
			.addCase(getContacts.fulfilled, (state, action) => {
				state.contacts = action.payload
				state.loading =  false
			}) 
			.addCase(getContacts.rejected, state => {
				state.loading =  false
			})


            .addCase(getPromotions.pending, state => {
                state.loading =  true
            })
            .addCase(getPromotions.fulfilled, (state, action) => {
                state.promotions = action.payload
                state.loading =  false
            })
            .addCase(getPromotions.rejected, state => {
                state.loading =  false
            })

            .addCase(getSimilarProducts.pending, state => {
                state.loading =  true
            })
            .addCase(getSimilarProducts.fulfilled, (state, action) => {
                state.similarProducts = action.payload
                state.loading =  false
            })
            .addCase(getSimilarProducts.rejected, state => {
                state.loading =  false
            })




    }
}))

export default productSlice.reducer