
import React, { useState} from "react";
import '../styles/catalogueItem.scss'
import { message} from 'antd';
import { useAppDispatch } from "../redux/hooks";
import { IProduct } from "../types";
import { addToCart } from "../redux/CartSlice";
import { Link } from "react-router-dom";

const CatalogueItem: React.FC<{product: IProduct | undefined}> = ({product}) => {
    const dispatch= useAppDispatch()
	const [isHovered, setIsHovered] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
	const onMouseEnter = () => setIsHovered(true);
	const onMouseLeave = () => setIsHovered(false);

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Товар был добавлен в корзинку!',
        });
    };

    const addProductToCart = (product: IProduct) => {
        const data = {
            productId: product?.id,
            id: Date.now(),
            name: product?.title,
            price: product?.price,
            quantity: 1,
            img: product?.product_images[0]?.image,
            color: product?.colors[0]?.color?.color_code,
            size: 'L'
        }
        dispatch(addToCart(data))
        success()
    }



    return <div className="catalogueItem"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
        {contextHolder}
        <Link
            to={`/product/${product?.id}`}
            style={{textDecoration: 'none',
                color: 'inherit',
            }}>
	<div className="catalogue_item_img_block">
			{
				isHovered
				? <img
                        src={product?.product_images[0]?.image}
                        alt=""
                        className="catalogue_item_img"/>
				: <img src={product?.product_images[1]?.image} alt="" className="catalogue_item_img"/>
			}
	</div>
        <div className={product && product?.discount_percentage > 0 ? "top_position" : ''} >
            {product && product?.discount_percentage > 0 && <div className="catalogue_item_discount">-{product?.discount_percentage}%</div>}
        </div>
        <div className={product && product?.discount_percentage > 0 ? "bottom_position" : 'top_position'}>{product?.is_bestseller && <div className="catalogue_item_new">HIT</div>}</div>
        </Link>
	<div className="catalogue_item_text">
        {isHovered
            ? <div className="addToCart" onClick={()=> product && addProductToCart(product) }>+ Добавить в корзинку</div>
            : <div className="catalogue_item_title">{product?.title}</div>}
		<div className="catalogue_item_price">
            {product?.discount_price ? <div className="catalogue_item_discount_price" style={{marginRight: product?.discount_price && '15px' }}>{product?.price} {product?.price && 'cом'}</div> : null}
			<div className="catalogue_item_fact_price">{product?.discount_price ? product?.discount_price : product?.price} сом</div>
		</div>
	</div>
  </div>
};

export default CatalogueItem;
