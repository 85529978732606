import React from "react";
import '../styles/footer.scss'
import {
    PhoneOutlined,
    EnvironmentOutlined,
    InstagramOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    AppstoreOutlined
} from "@ant-design/icons";
import { useAppSelector } from "../redux/hooks";
import {Link} from "react-router-dom";


const Footer = () => {
	const contacts = useAppSelector(state => state.products.contacts)
  return <div className="footer">
	<div className="footer_logo_block">
		<div className="logo">Bikini</div>
	</div>
	<div className="footer_block">
		<div className="footer_title">КОНТАКТЫ:</div>
		<div className="footer_item">
			<PhoneOutlined style={{color: 'black', marginRight: '5px'}} /> {Array.isArray(contacts) && contacts[0]?.phone_first}
		</div>
		<div className="footer_item">
			<EnvironmentOutlined style={{color: 'black', marginRight: '5px'}}/> {Array.isArray(contacts) && contacts[0]?.address}
		</div>
		<div className="footer_item">
			<InstagramOutlined style={{color: 'black', marginRight: '5px'}} /> {Array.isArray(contacts) && contacts[0]?.instagram}
		</div>
	</div>
	<div className="footer_block">
		<div className="footer_title">ПОЛЕЗНЫЕ ССЫЛКИ:</div>
        <div className="links_wrapper">
            <Link to={'/'} style={{textDecoration: 'none'}} className="footer_item"><HomeOutlined style={{color: 'black', marginRight: '5px', textDecoration: 'none'}}/> Главная</Link>
            <Link to={'/catalogue'} style={{textDecoration: 'none'}} className="footer_item"><AppstoreOutlined style={{color: 'black', marginRight: '5px'}}/> Каталог</Link>
            <Link to={'/cart'} style={{textDecoration: 'none'}} className="footer_item"><ShoppingCartOutlined style={{color: 'black', marginRight: '5px', textDecoration: 'none'}}/> Корзинка</Link>
        </div>


	</div>
  </div>;
};

export default Footer;
