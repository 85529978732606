import React from "react";
import AddItem from "./AddItem";
import '../styles/add.scss'
import { useAppSelector } from "../redux/hooks";


const Add = () => {
    const promotions = useAppSelector(state => state.products.promotions)
  return <div className="add">
      {Array.isArray(promotions) && promotions?.length > 0 && <AddItem title={promotions[1]?.promo_text} img={promotions[1]?.photo}/>}
      { Array.isArray(promotions) && promotions?.length > 1 && <AddItem title={promotions[2]?.promo_text} img={promotions[2]?.photo}/>}
  </div>;
};

export default Add;
