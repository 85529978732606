
import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import { getCategories, getContacts, getProducts, getPromotions, getSliderData } from '../redux/ProductSlice'
import {useAppDispatch, useAppSelector} from '../redux/hooks'
import MainSlider from '../components/MainSlider'
import CategoriesSlider from '../components/CategoriesSlider'
import CatalogueList from '../components/CatalogueList'
import Sales from '../components/Sales'
import Add from '../components/Add'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import ScrollToTop from "../components/ScrollToTop";
import {Link} from "react-router-dom";
import '../styles/catalogue.scss'


export default function Home() {
    const dispatch = useAppDispatch()
    const discountsRef = useRef () as MutableRefObject<HTMLDivElement>
    const contactsRef = useRef() as MutableRefObject<HTMLDivElement>
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const categories = useAppSelector(state => state.products.categories)



    function getWindowDimensions() {
        if (typeof window !== 'undefined') {
            const {innerWidth: width, innerHeight: height} = window;
            return {
                width,
                height
            };
        }
    }
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    useEffect(() => {
    dispatch(getProducts())
    dispatch(getSliderData())
    dispatch(getCategories())
    dispatch(getContacts())
    dispatch(getPromotions())
}, [])


     const scrollToComponent = (component: string) => {
        if(component === 'discounts'){
             discountsRef.current.scrollIntoView()
        }
        if(component === 'contacts'){
            contactsRef.current.scrollIntoView()
        }
    }

  return (
    <div>
        <Menu scrollToComponent={scrollToComponent }/>
        <MainSlider />
        <div className="catalogue_component_title">
            Каталог
        </div>
        {windowDimensions && windowDimensions.width > 600
            ? <CategoriesSlider/>
            : <div className={'mobile_categories'}>
                {
                    categories.map(it => (
                        <Link to={'/catalogue'} style={{textDecoration: 'none', color: 'inherit'}}>
                            <div className={'mobile_category_item'}>
                                {it.name}
                            </div>
                        </Link>
                    ))
                }
            </div>
        }
        <CatalogueList />
        <div ref={discountsRef}>
          <Sales />
        </div>
        <Add/>
        <div ref={contactsRef}>
          <Footer />
        </div>
        <ScrollToTop />
      </div>
  )
}