import React from "react";
import '../styles/catalogueTopBar.scss'


const CatalogueTopBar: React.FC<{name: String}> = ({name}) => {
  return <div className="top_bar">
	<img src={'/assets/1.png'} alt='' className="top_bar_image"/>
	<div className="catalogue_title">{name} </div>
	</div>;
};

export default CatalogueTopBar;
