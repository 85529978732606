
import React, {MutableRefObject, useEffect, useRef} from "react";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {getProductDetails, getProductsByCategory} from "../redux/ProductSlice";
import Menu from "../components/Menu";
import CatalogueTopBar from "../components/CatalogueTopBar";
import Sidebar from "../components/Sidebar";
import CatalogueItem from "../components/CatalogueItem";
import { IProduct } from "../types";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";

const CataloguePage: React.FC = () => {
      const products = useAppSelector(state => state.products.products)
      const dispatch = useAppDispatch()
      const categories = useAppSelector(state => state.products.categories)
      const contactsRef = useRef() as MutableRefObject<HTMLDivElement>
      const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const onCategoryChangeHandler = (e:any) => {
        if(e.key){
            dispatch(getProductsByCategory(Number(e.key)))
            e.domEvent.stopPropagation();
        }

        if(!e.key){
            let id =  categories.find(it => it.name === e.target.innerText)?.id
            dispatch(getProductsByCategory(Number(id)))
        }
    }


    const scrollToComponent = (component: string) => {
          if(component === 'contacts'){
              contactsRef.current.scrollIntoView()
          }
      }

  return <div className="catalogue_page">
          <div className="menu_wrapper">
            <Menu scrollToComponent={scrollToComponent}/>
          </div>
          <CatalogueTopBar name={'КАТАЛОГ'} />
          <div className="catalogue_body">
            <div className="catalogue_sidebar">
              <Sidebar
                  onCategoryChangeHandler ={onCategoryChangeHandler}
              />
            </div>
              <div className="catalogue_list">
                {
                  products?.map((it: IProduct, index: number) => (
                        <div key={it.id} onClick={() => dispatch(getProductDetails(it.id))}>
                          <CatalogueItem product={it} key={index}/>
                        </div>
              
                    ))
				  }
              </div>
            </div>
            <div ref={contactsRef}>
                <Footer />
            </div>
      <ScrollToTop />
  </div>
};



export default CataloguePage;
