
import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../styles/categoriesSlider.scss'
import { Categories } from "../types";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Link } from "react-router-dom";
import { getProductsByCategory } from "../redux/ProductSlice";


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};



const CategoriesSlider = () => {

  const categories: Categories[] = useAppSelector(state => state.products.categories)
    const dispatch = useAppDispatch()

  return (
	<aside className='my-own-custom-container'>
		<Carousel 
		 	arrows={true}
			responsive={responsive} 
			className="carousel">
        {
            Array.isArray(categories) &&  categories?.map((it, index) => (
            <div className="category_slider_item" key={index}>
              <Link to={'/catalogue'} className="category_slider_item_text"  onClick={() => dispatch(getProductsByCategory(Number(it?.id)))}>
                <div className="category_slider_item_title">{it?.name}</div>
                {/*<button className="category_slider_item_btn">В ката</button>*/}
              </Link>
              <Link to={'/catalogue'} className={'catalogue_link'}>
                  <div className="category_slider_item_img_wrapper"
                       style={{width: '100%', height: '100%'}}
                       onClick={() => dispatch(getProductsByCategory(Number(it?.id)))}
                  >
                    <img
                      src={it?.photo}
                      alt=""
                      className="category_slider_item_img"
                      />
                  </div>
              </Link>
            </div>
          ))
        }
		</Carousel>
	</aside>
  )
};

export default CategoriesSlider;

