
import React from "react";
import CatalogueItem from "./CatalogueItem";
import '../styles/catalogue.scss'
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { IProduct } from "../types";
import { getProductDetails, getSimilarProducts } from "../redux/ProductSlice";
import {Link} from "react-router-dom";


const CatalogueList = () => {
	const products = useAppSelector(state => state.products.products)
	const dispatch = useAppDispatch()

  return <div className="catalogue_component">
			 <div className="catalogue_component_title">
				 Популярные товары
			</div>
			<div className="catalogue_component_wrapper">
				{
					products?.map((it: IProduct) => (
						<div key={it.id} onClick={() => {dispatch(getProductDetails(it.id)); dispatch(getSimilarProducts(it.id))}}>
							<CatalogueItem product={it} key={it.id}/>
						</div>
					))
				}
			</div>
             <div className="btn_wrapper">
                 <Link to={'/catalogue'} style={{textDecoration: 'none'}} className="catalogue_btn">В каталог</Link>
             </div>
			</div>
  	
};

export default CatalogueList

