'use client'
import React from "react";
import '../styles/sales.scss'
import { IPromotion } from "../types";
import { useAppSelector } from "../redux/hooks";


const Sales = () => {
  const promotions: IPromotion[] | [] = useAppSelector(state => state.products.promotions)
  return <div className="sales">
	<div className="sales_img_block">
		{Array.isArray(promotions) && promotions[0]?.photo ? <img src={promotions[0].photo} alt='' className="sales_img" /> : null}
	</div>
	<div className="sales_text">
		<div className="sales_title">{Array.isArray(promotions) && promotions[0]?.promo_text ? promotions[0]?.promo_text : null}</div>
	</div>
  </div>;
};

export default Sales;
