import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/HomePage';
import CataloguePage from './Pages/CataloguePage';
import Cart from './Pages/Cart';
import ProductDetails from './Pages/ProductDetails';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<Home />}/>
              <Route path='/catalogue' element={<CataloguePage />}/>
              <Route path='/cart' element={<Cart />}/>
              <Route path='/product/:id' element={<ProductDetails />}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
