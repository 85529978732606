
import React from "react";

interface Props{
	title: string
	img: any
}

const AddItem = ({title, img}: Props) => {
  return <div className="add_item">
	<div className="add_img_block">
       <img src={img} alt='' className="add_img"/>
		<div className="add_text">
			<div className="add_text_inner">
				{title}
			</div>
			</div>
		</div>
  </div>;
};

export default AddItem;
