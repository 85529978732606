
import React, {MutableRefObject, useRef, useState} from "react";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import '../styles/productDetails.scss'
import { Select } from "antd";
import { message } from 'antd';
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { addToCart} from "../redux/CartSlice";
import Menu from "../components/Menu";
import CatalogueTopBar from "../components/CatalogueTopBar";
import { IImage } from "../types";
import CatalogueItem from "../components/CatalogueItem";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";


const ProductDetailsPage = () => {
    const product = useAppSelector(state => state.products.product)
    const dispatch = useAppDispatch()
    const [quantity, setQuantity] = useState<number>(1)
    const [color, setColor] = useState<String | undefined>(product && product?.colors[0]?.color?.color_code)
    const [size, setSize] = useState<String>('L')
    const [messageApi, contextHolder] = message.useMessage();
    const similar = useAppSelector(state => state.products.similarProducts)
    const contactsRef = useRef() as MutableRefObject<HTMLDivElement>


    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Товар был добавлен в корзину!',
        });
    };

    const addProductToCart = () => {
        const data = {
            productId: product?.id,
            id: Date.now(),
            name: product?.title,
            img: product?.product_images[0]?.image,
            quantity,
            size,
            color,
            price: Number(product?.discount_price ? product.discount_price : product?.price)
        }
        dispatch(addToCart(data))
        success()
    }

    const scrollToComponent = (component: string) => {
        if(component === 'contacts'){
            contactsRef.current.scrollIntoView()
        }
    }


  return <div>
      {contextHolder}
    <div className="menu_wrapper">
      <Menu scrollToComponent={scrollToComponent}/>
    </div>
      <div className={'product_details_page'}>
          <CatalogueTopBar name={'ПОДРОБНЕЕ О ТОВАРЕ'}/>
          <div className="productDetailBody">
              <div className="product_details_slider">
                  <ImageGallery
                      items={ product?.product_images.map((it: IImage) => ({
                          original: it.image,
                          thumbnail: it.image,
                      }))}
                      showFullscreenButton={false}
                      showPlayButton={false}
                  />
              </div>
              <div className="products_details_description">
                  <div className="product_title">{product?.title}</div>
                  <div className="product_price_block">
                      <div className="product_sale_price">{product?.price} {product?.price && 'сом'}</div>
                      <div className="product_price">{product?.discount_price} сом</div>
                  </div>
                  <div className="product_description"  dangerouslySetInnerHTML={product?.description ? {__html: product!.description } : {__html: ''}  }></div>
                  <div className="bold">Paзмеры:</div>
                  <div className="product_sizes">
                      <Select
                          defaultValue="L"
                          style={{ width: 120 }}
                          options={[
                              { value: 'XXS', label: 'XXS' },
                              { value: 'XS', label: 'XS' },
                              { value: 'S', label: 'S' },
                              { value: 'M', label: 'M' },
                              { value: 'L', label: 'L' },
                              { value: 'XL', label: 'XL' },
                              { value: 'XXL', label: 'XXL' },
                              { value: 'XXXL', label: 'XXXL' },
                              { value: 'XXXXL', label: 'XXXXL' },
                          ]}
                          onSelect={(value) => setSize(value)}
                      />
                  </div>
                  <div className="bold">Цвета:</div>
                  <div className="product_colors">
                      {
                          product?.colors?.map((it: any, index: number) => (
                              <div className={it?.color?.color_code === color ? 'selected' : 'not_selected'} key={index}>
                                  <div className="product_color"
                                       style={{background: it?.color?.color_code}}
                                       onClick={() => setColor(it?.color?.color_code)}>
                                  </div>
                              </div>
                          ))
                      }
                  </div>
                  <div className="product_quantity">
                      <div className="bold">  Количество:</div>
                      <div className="quantity">
                          <button className="quantity_btn" onClick={() => {quantity > 1 && setQuantity(quantity - 1)}}>-</button>
                          <div className='quantity_number'>{quantity}</div>
                          <button className="quantity_btn" onClick={() => setQuantity(quantity + 1)}>+</button>
                      </div>
                  </div>
                  <div className="btn_wrapper">
                      <div className="product_btn" onClick={() => addProductToCart()}> В корзину</div>
                  </div>

              </div>
          </div>
          <div className={'header'}>Похожие товары</div>
          <div className="similar_products">
              {similar?.map((it, index) => (
                  index <= 8
                      ? <CatalogueItem product={it} key={it.id}/> : null
              ))
              }
          </div>
          <div ref={contactsRef}>
              <Footer />
          </div>
      </div>
      <ScrollToTop />
  </div>
};

export default ProductDetailsPage;